import React from 'react'
import SaleRetuen from './Partials/SaleRetuen'

const SalesReturnReportMain = () => {
  return (
    <div>
        <SaleRetuen />
    </div>
  )
}
export default SalesReturnReportMain

