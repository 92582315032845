import React from 'react'
import QuatationReport from './Partials/QuatationReport'

const QuatationReportMain = () => {
  return (
    <div>
        <QuatationReport />
    </div>
  )
}

export default QuatationReportMain