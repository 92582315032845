import React from 'react'
import PurchaseReturn from './Partials/PurchaseReturn'

const PurchaseReturnReportMain = () => {
  return (
    <div>
        <PurchaseReturn />
    </div>
  )
}

export default PurchaseReturnReportMain