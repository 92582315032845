import React from 'react'
import ReportSale from './Partials/ReportSale'

const SaleReportMain = () => {
  return (
    <div>
        <ReportSale />
    </div>
  )
}

export default SaleReportMain